.company-info {
  padding: 3.125rem 0;
  .company-info-logo {
    width: 100%;
    max-width: 270px;
  }
  .company-contact {
    text-transform: capitalize;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
    h4 {
      margin-bottom: 1.5rem;
    }
    .contact {
      display: flex;
      margin-top: .5rem;
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
    }
    .contact-des {
      font-size: 0.875rem;
      color: #000000;
      margin-left: .5rem;
    }
  }
}

.company-invoice {
  text-align: center;
  .btn-payment {
    width: 100%;
    max-width: 15.5rem;
    border-radius: 312.5rem !important;
    color: #fff !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
  }

  .btn-pay-invoice {
    border-radius: 0.25rem;
    height: 3rem;
    width: 100%;
    font-size: 0.75rem !important;
    font-weight: 900 !important;
    text-transform: uppercase !important;
    margin-top: 1.5rem;
  }
  .notice {
    margin-top: .5rem;
    font-size: .875rem;
    color: #6c6c6c;
  }
}
@media screen and (max-width: 768px) {
  .companyInfo {
    padding: 1rem 0;
  }
  .company-info-logo {
    text-align: center;
    max-width: unset !important;
    
    img {
      width: 78px !important;
      height: 78px !important;
      background-color: #fff;
      // box-shadow: 0px 12px 32px -8px rgba(42, 42, 42, 0.24);
      border-radius: 50%;
      margin-bottom: .5rem
    }
  }
  .company-invoice {
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    .btn-payment {
      margin-top: 3.5rem;
    }
    .btn-pay-invoice {
      margin-top: 2.5rem;
    }
  }
}