.show-photos-carousel {
  max-width: 100% !important;
  margin: 3rem auto 0 !important;
  min-height: calc(100% - 3rem);
  .modal-content {
    background: #fafbfc;
    border: unset;
    border-radius: unset;
    .estimate-carousel {
      padding: 1.5rem;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      .carousel-item {
        img {
          height: 25rem;
          object-fit: cover;
        }
      }

      .carousel-control-prev, .carousel-control-next {
        top: -140px;
      }

      .carousel-caption {
        padding-top: 1.5rem;
        padding-bottom: 1.875rem;
        position: relative !important;
        color: #171E23;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .control-carousel {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-download-photos {
          text-transform: uppercase;
          margin-top: 1.25rem;
          max-width: 17.5rem;
        }
      }
    }
    .show-photo-modals {
      .show-photo {
        h6 {
          font-weight: 600;
        }
        .after {
          margin-top: 1rem;
        }
        img {
          height: 15rem;
          object-fit: cover;
        }
      }
    }
  }
  .back-link {
    margin-top: 2.5rem;
    text-align: center;
  }
  @media screen and (min-width: 768px) {
    max-width: 768px !important;
    margin: 7.5rem auto 0 !important;
    min-height: unset !important;
    .modal-body {
      background-color: #ffffff;
    }
    .company-info {
      display: none;
    }
    .footer {
      display: none;
    }
    .modal-content {
      background: #ffffff !important;
      .estimate-carousel {
        box-shadow: unset;
      }
    }
    .back-link {
      margin-top: unset;
      margin-bottom: 1.5rem;
    }
    .show-photo-modals {
      .show-photo {
        h6 {
          font-weight: 600;
        }
        .after {
          margin-top: 1rem;
        }
        img {
          height: 15rem;
          object-fit: cover;
        }
      }
    }
  }
}