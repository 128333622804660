.estimate {
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2.5rem;
  margin: 0 !important;
  .e-header {
    padding: 1rem 2.5rem 0;
    .e-header-flex {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .box1 {
        padding-left: 1.5rem;
        p {
          width: 4rem;
        }
      }
      .border-vertical {
        height: 6.7rem;
        width: 1px;
        border-left: 1px solid #ececec;
        margin: 0 2.5rem !important;
      }
      .box2 {
        h6 {
          font-weight: bold;
        }
      }
      .box3 {
        padding-right: 1.5rem;
        text-align: right;
        flex-grow: 1;
      }
    }
  }
  .vehicle-detail-row {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .estimate {
    margin: 1.5rem 0 0 !important;
    .e-header-flex {
      flex-direction: column !important;
      text-align: center;
      .box1 {
        order: 1;
        padding-left: 0 !important;
      }
      .box2 {
        order: 3;
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem !important;
        h6 {
          order: 2;
          font-size: 0.875rem;
          font-weight: 600;
          color: #696969;
        }
        h5 {
          order: 1;
          margin: 0;
          font-size: 2rem;
          font-weight: 400;
        }
        .amount-info {
          order: 3;
        }
      }
      .box3 {
        order: 2;
        text-align: center !important;
        padding-right: 0 !important;
        margin-top: 1.25rem !important;
      }
      .border-vertical {
        display: none;
      }
    }
  }
  .vehicle-detail-row {
    padding: 1.5rem;
  }
}