::-webkit-scrollbar {
  display: none;
}



html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Source Sans Pro', sans-serif;
  // font-family: 'Inter', sans-serif;
  background: #fbfcfd !important;
  color: #171e23 !important;

  button, [type='button'], [type='reset'], [type='submit'] {
    -webkit-appearance: none;
  }

  &.unscrollable {
    overflow: hidden;
    .touch-device & {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  overflow-x: hidden;
}


div[role='dialog'] {
  z-index: 1049;
}

.hidden {
  display: none !important;
}

@media screen and (min-width: 1200px) {
	.container {
		max-width: 1200px !important;
	}
}

.btn-warning {
	border-color: #F1BE0C !important;
	background-color: #F1BE0C !important;
}

.btn-primary {
	border-color: #007AFF !important;
	background-color: #007AFF !important;
}

.font-75 {
	font-size: 0.75rem !important;
}

.font-875 {
	font-size: 0.875rem !important;
}
.font-625 {
  font-size: 0.625rem;
}
.font-weight-600 {
	font-weight: 600;
}
.border-vertical  {
  height: 100%;
  width: 1px;
  border-left: 1px solid #ececec;
  margin: 0 2.5rem !important;
}

.alert-success {
  background-color: #28A038 !important;
  color: #ffffff;
}

.mobile-none {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.spinner-app {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1052;
  background: #fff;
  opacity: 0.7;
  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}