@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
@import './variables';
@import './lib/bootrap';
@import './base';
@import './form';

.container-toast-popup {
  position: relative;

  .Toastify__toast-container {
    width: 100%;
    max-width: 37.5rem;
  }

  .Toastify__toast-container--top-right {
    padding: 1.5rem;
    top: 3.375rem;
    right: 0;
    @media screen and (min-width: 768px) {
      padding: 0;
      top: 7.5rem;
      right: 1.5rem;
    }
  }

  .Toastify__toast {
    padding: 1.25rem;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    &::before {
      position: static;
      height: 24px;
      width: 24px;
      left: 0%;
      top: 0px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-right: 0.5rem;
      content: '';
    }
    &.Toastify__toast--success {
      background: #28A038;
      color: #ffffff;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM7.03813 11.5C7.42866 11.1095 8.06182 11.1095 8.45235 11.5L10.7547 13.7166L15.5134 8.12394C15.8829 7.71343 16.5152 7.68015 16.9257 8.04961C17.3362 8.41907 17.3695 9.05136 17 9.46187L11.5362 15.8379C11.1529 16.2638 10.4909 16.2812 10.0858 15.8761L7.03813 12.9142C6.64761 12.5237 6.64761 11.8905 7.03813 11.5Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }

    &.Toastify__toast--warning {
      color: #ffffff;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.6784 2.27883C14.1885 2.53387 14.6021 2.94747 14.8571 3.45754L22.2212 18.1856C22.8721 19.4876 22.3444 21.0708 21.0425 21.7217C20.6765 21.9047 20.2729 22 19.8638 22H5.13567C3.68003 22 2.5 20.82 2.5 19.3643C2.5 18.9552 2.59527 18.5516 2.77826 18.1856L10.1423 3.45754C10.7933 2.15558 12.3765 1.62785 13.6784 2.27883ZM11.7372 4.47154L4.59002 18.766C4.53082 18.8844 4.5 19.0149 4.5 19.1473C4.5 19.6182 4.88176 20 5.35269 20H19.6471C19.7795 20 19.9101 19.9692 20.0285 19.91C20.4497 19.6994 20.6204 19.1872 20.4098 18.766L13.2626 4.47154C13.1801 4.30652 13.0463 4.17272 12.8812 4.09021C12.46 3.8796 11.9478 4.05033 11.7372 4.47154ZM12.5 16C13.0523 16 13.5 16.4477 13.5 17C13.5 17.5523 13.0523 18 12.5 18C11.9477 18 11.5 17.5523 11.5 17C11.5 16.4477 11.9477 16 12.5 16ZM12.5 8C13.0523 8 13.5 8.44772 13.5 9V13C13.5 13.5523 13.0523 14 12.5 14C11.9477 14 11.5 13.5523 11.5 13V9C11.5 8.44772 11.9477 8 12.5 8Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }
}