.pay-invoice-modal {
  max-width: 100% !important;
  margin: 3rem auto 0 !important;

  @media screen and (min-width: 768px) {
    margin: 7.5rem auto 0 !important;
  }

  @media screen and (min-width: 963px) {
    max-width: 963px !important;
  }

  .modal-content {
    border: unset !important;
    border-radius: unset !important;
  }

  .modal-header {
    justify-content: flex-end;
    border-bottom: unset !important;
  }

  .modal-body {
    padding: 0 2rem 2rem !important;
  }

  .car {
    text-align: center;
  }

  .power-by {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center;

    img {
      width: 130px;
    }
  }

  .btn-pay-apple {
    background-color: #000;
    border-color: #000;
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;

    img {
      width: 20px;
      display: inline-block;
      margin: -2px 8px 0 0;
    }
  }

  .another-method {
    font-weight: 600;
    color: #A2A2A2;
    font-size: 13px;
    padding: 23px 0 16px;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(#000, .05);
    }

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      background-color: #fff;
      padding: 0 10px;
    }
  }

  .invoice-info {
    padding: 2.75rem 2rem;
    text-align: center;

    .logo-area {
      .invoice-logo {
        width: 4.875rem;
        height: 4.875rem;
        border-radius: 50%;
        margin-bottom: 0.5rem;
        box-shadow: 0px 12px 32px -8px rgba(42, 42, 42, 0.24);
      }
    }

    .footer-area {
      color: #A2A2A2;

      .title {
        font-size: .875rem;
        font-weight: 600;

        .title-point {
          font-size: 1rem;
          font-weight: bold;
          color: #000000;
        }
      }
    }
  }

  .card-form {
    margin-top: 2.75rem;

    .form-label {
      color: #2a2a2a;
      font-size: 0.875rem;
      font-weight: 600;
    }

    .form-control {
      background-color: #F2F2F2;
      font-weight: 400;
      font-size: 0.875rem;
    }
  }

  .pay-with-card {
    padding: 1rem;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 767px) {
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }

  .decord-expiry {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-right: unset !important;
  }



  .decor-cards {
    background: url(/images/Cards@2x.png) no-repeat calc(100% - 16px) center;
    background-size: auto 15px;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-bottom: unset !important;
  }

  .group-cvc {
    .decor-cvc {
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }

    img {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }


  .tip {
    .tip-block {
      width: 49%;
      // @media screen and (max-width: 767px) {
      //   width: 19%;
      // }

      &.active {
        background-color: #007AFF;
        color: #ffffff;
      }

      img {
        width: 1rem;
      }

      .tip-price {
        font-size: 0.625rem;
        color: #6C6C6C;
      }
    }
  }

  .btn-pay-card {
    min-height: 3rem;
    font-size: 0.75rem;
    font-weight: 900;
  }

  .pay-different-amount {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    margin-top: 1.25rem;
  }

  .form-control {

    &.StripeElement {
      padding: 10px;
    }

    &.StripeElement--invalid {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .CardNumberField-input-wrapper {
      left: 0 !important;
    }

    .CardBrandIcon-container {
      right: 2rem !important;
    }
  }
}

.receipt {
  .invoice-total {
    font-weight: bold;
    margin-top: 3.875rem;

    @media screen and (max-width: 768px) {
      margin-top: 1.5rem;
    }
  }

  .invoice-number {
    color: #6c6c6c;
    font-size: .875rem;
    font-weight: normal;
  }

  .deposit-paid {
    color: #28A038;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .btn-payment-success {
    height: 2.5rem;
    border-radius: 312.5rem;
    margin: 1.5rem auto;
    width: 75%;
    font-weight: bold;
  }

  .payment-verify {
    width: 100%;
    max-width: 362px;
    margin: 3.5rem auto;

    @media screen and (max-width: 768px) {
      display: none !important;
    }

    .payment-verify-right {
      width: 147px;
      text-align: right;
    }
  }
}
