.form {

  .hide {
    display: none;
  }

  fieldset {
    // margin-top: 3rem;
    margin-top: 2rem;

    &:first-child {
      margin-top: unset;
    }

    legend {
      color: #353535;
      font-size: 0.75rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: rgba(#000000, 0.5);
      font-size: 0.875rem;
      // margin-top: -0.75rem;
      margin-bottom: 1rem;
    }
  }

  .btn-save, .btn-cancel {
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    border-radius: 0.125rem;
    padding: 0.75rem 1.5rem;
  }

  .form-group {
    position: relative;
    background-color: rgba(#696969, 0.12);
    border: 0.0625rem solid transparent;
    border-radius: 0.25rem;
    padding: 0.75rem 0.875rem;

    &:focus-within {
      background-color: #ffffff;
      border-color: $bright-blue;

      .form-label {
        color: $bright-blue;
      }
    }

    &.has-error {
      background-color: rgba(#cc0000, 0.25);
      border-bottom: 0.125rem solid #cc0000;

      span {

        &.has-error {
          color: #cc0000;
          font-size: 0.75rem;
        }
      }
    }

    .form-label {
      color: #929292;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: unset;
    }

    .form-control {
      color: #000000;
      background-color: transparent;
      font-size: 1rem;
      border: unset;
      border-radius: unset;
      padding-left: unset;
      padding-right: unset;

      &::placeholder {
        color: rgba(#000000, 0.5);
      }
      &:focus {
        box-shadow: unset;
      }
    }

    .form-action {
      position: absolute;
      top: 1rem;
      right: 0.875rem;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;

      &.hide {
        display: none;
      }

      &.delete {
        color: #dc3545;
      }

      &.upgrade {
        color: $brand-blue;
      }

      &.change {
        color: #939393;
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent url('/assets/images/icon-arrow-down.png') no-repeat 100% center;
    }
  }

  .form-switch {
    position: relative;
    width: 100%;
    height: 2.25rem;
    padding: 0.25rem;

    .switch-input {
      display: none;
    }
    .switch-input-02:checked ~ .switcher {
      left: 50%;
    }

    .switch-label {
      position: inherit;
      width: 50%;
      color: rgba(#000000, 0.75);
      font-size: 0.75rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: unset;
      cursor: pointer;
      z-index: 10;

      .icon {
        height: 0.75rem;
        margin-top: -0.125rem;
        margin-left: 0.25rem;
      }
    }
    .switcher {
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      width: 50%;
      height: 1.875rem;
      background-color: #ffffff;
      transition: all 0.35s ease-in;
      cursor: pointer;
      z-index: 5;
    }
  }

  .form-choose {
    position: relative;
    width: 100%;
    height: 2.25rem;
    padding: 0.25rem;
    .choose-label {
      text-transform: uppercase;
    }
    .choose-input {
      display: none;
    }

    .choose-input-02:checked ~ .chooser {
      left: 25%;
    }
    .choose-input-03:checked ~ .chooser {
      left: 50%;
    }
    .choose-input-04:checked ~ .chooser {
      left: 75%;
    }

    .choose-label {
      position: inherit;
      width: 25%;
      color: rgba(#A6A6A6, 0.75);
      font-size: 0.75rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: unset;
      cursor: pointer;
      z-index: 10;
    }

    .active {
      color: rgba(#000000, 0.75);
    }
    .chooser {
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      width: 25%;
      height: 1.875rem;
      background-color: #ffffff;
      transition: all 0.35s ease-in;
      cursor: pointer;
      z-index: 5;
    }

    &.\32 {
      .choose-label {
        width: 50%;
      }
      .chooser {
        width: 50%;
      }
      .choose-input-02:checked ~ .chooser {
        left: 50%;
      }
    }
    &.\33 {
      .choose-label {
        width: 33.33%;
      }
      .chooser {
        width: 33.33%;
      }
      .choose-input-02:checked ~ .chooser {
        left: 33.33%;
      }
      .choose-input-03:checked ~ .chooser {
        left: 66.66%;
      }
    }
    &.\34 {
      .choose-label {
        width: 25%;
      }
      .chooser {
        width: 25%;
      }
      .choose-input-02:checked ~ .chooser {
        left: 25%;
      }
      .choose-input-03:checked ~ .chooser {
        left: 50%;
      }
      .choose-input-04:checked ~ .chooser {
        left: 75%;
      }
    }
  }

  .form-check {
    background-color: unset;
    padding-left: 1rem;

    &:focus-within {
      background-color: unset;
      border-color: transparent;
    }
    &.has-error {
      border-bottom: unset;
    }

    .form-check-input {
      margin-top: 0.125rem;
      margin-left: -0.875rem;
    }
    .form-check-label {
      color: rgba(#000000, 0.5);
      font-size: 0.75rem;
      margin-left: 0.75rem;
    }
  }

  .form-toggle {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    align-items: center;

    .form-label {
      color: #000000;
      // line-height: 2.25rem;
      margin-right: 1.25rem;
      margin-bottom: unset;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 2.25rem;
    }

    .switch input {display:none;}

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 1.75rem;
      width: 1.75rem;
      left: 0.25rem;
      bottom: 0.25rem;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: $bright-blue;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $bright-blue;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}