.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1050;
  background: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);

  .container {
    justify-content: center;
    height: 5rem;
    .logo {
      width: 13.25rem;
      height: auto;
    }
  }
  .btn-mobile {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .container {
      height: 3.375rem;
      justify-content: center;
      .logo {
        width: 8.625rem;
        height: 1.5rem;
      }
    }
    .btn-mobile {
      display: block;
      background-color: unset !important;
      border: unset !important;
    }
    .main {
      padding: 0;
    }
  }
}
.navbarBrand {
  padding-bottom: 0.5375rem;
  padding-top: 0.3125rem;
  margin-right: 3.75rem;
  cursor: pointer;
  display: inline-block;
  
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navItem {
  margin-right: 2.25rem;
  position: relative;
}
.main {
  padding: 2.5rem 0 0;
}
.navLink {
  color: .353535;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  border-bottom: 0.25rem solid transparent;
  padding: 2rem 0;
  white-space: nowrap;
  cursor: pointer;
}
.footer {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  img {
    height: 2.5rem;
  }
  @media screen and (min-width: 768px) {
    padding: 5rem 0 2.5rem;
  }
}
